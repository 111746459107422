import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled, { useTheme } from 'styled-components'
import { Line, LineChart, Tooltip } from 'recharts'
import { Icon } from '@junglescout/edna'

import { TEXT_STYLES } from 'TEXT_STYLES'

const TrendContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  max-height: 18px;
`

const CaretIcon = ({ className = '' }) => (
  <Icon name="CARET_UP" className={className} width="30px" height="30px" />
)

CaretIcon.defaultProps = {
  className: ''
}
CaretIcon.propTypes = {
  className: PropTypes.string
}

const TrendIndicator = styled(CaretIcon)`
  color: ${props =>
    props.isUp ? props.theme.colors.green500 : props.theme.colors.red500};
  transform: ${props => (props.isUp ? 'rotate(0)' : 'rotate(180deg)')};
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
`

const TrendValue = styled.div`
  ${TEXT_STYLES.BodyBlack}

  line-height: 1.5 !important;

  text-align: right;
  min-width: 39px;
`

const TooltipCursorClass = 'recharts-tooltip-cursor'

const TrendGraphContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-right: 4px;

  & .${TooltipCursorClass} {
    stroke-width: 0;
  }
`

const TrendGraphTooltip = styled.div`
  pointer-events: none;

  font-size: 12px;
  font-weight: 600;
  color: ${props => props.theme.colors.grey900};

  position: absolute;
  top: ${props => props.top}px;
  left: ${props => props.left}px;
`

const TrendGraphTooltipContent = styled.div`
  position: absolute;

  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.grey100};
  background-color: ${props => props.theme.colors.grey050};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);

  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  opacity: ${props => (props.show ? 1 : 0)};
  transform: translate(-50%, calc(-100% - 6px))
    ${props => (props.show ? 'scale(1)' : 'scale(0.8)')};

  padding: 4px;
`

const TrendGraphTooltipCursor = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: ${props => props.fill};
  width: 8px;
  height: 8px;
`

const AnimateShow = ({ children }) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    setShow(true)
  }, [])

  return children(show)
}

const PortalTrendGraphTooltip = ({ cx, cy, value, fill }) =>
  ReactDOM.createPortal(
    <AnimateShow>
      {show => (
        <TrendGraphTooltip top={cy} left={cx}>
          <TrendGraphTooltipContent show={show}>
            {parseFloat(value).toLocaleString('en-US')}
          </TrendGraphTooltipContent>
          <TrendGraphTooltipCursor fill={fill} />
        </TrendGraphTooltip>
      )}
    </AnimateShow>,
    document.body
  )

const TrendGraph = ({ data, trend, dataKey }) => {
  const { colors } = useTheme()
  const ref = useRef(null)
  const [isHovering, setIsHovering] = useState(false)
  const [boundingX, setBoundingX] = useState(0)
  const [boundingY, setBoundingY] = useState(0)

  useEffect(() => {
    // For performance reasons, we only want to add the event listeners
    // when the user is hovering over the graph.
    if (!ref.current || !isHovering) return () => {}

    const cb = () => {
      const { x, y } = ref.current.getBoundingClientRect()
      setBoundingX(x)
      setBoundingY(y)
    }

    window.addEventListener('scroll', cb, true)
    document.addEventListener('mousemove', cb, true)
    return () => {
      window.removeEventListener('scroll', cb, true)
      document.removeEventListener('mousemove', cb, true)
    }
  }, [ref, isHovering])

  if (!data || data.length < 2) {
    return null
  }

  let stroke = null

  switch (true) {
    case trend > 0:
      stroke = colors.green500
      break
    case trend < 0:
      stroke = colors.red500
      break
    default:
      stroke = colors.grey500
  }

  return (
    <TrendGraphContainer>
      <div
        ref={ref}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}>
        <LineChart
          data={data}
          cursor="pointer"
          width={110}
          height={40}
          margin={{ top: 20, right: 5, bottom: 0, left: 5 }}>
          <Line
            filter="drop-shadow(0 4px 11px rgba(85, 113, 185, 0.3))"
            type="monotone"
            dataKey={dataKey}
            stroke={stroke}
            strokeWidth={2}
            strokeLinecap="round"
            dot={false}
            activeDot={({ cx, cy, value, fill }) => (
              <PortalTrendGraphTooltip
                cx={cx + boundingX + window.scrollX}
                cy={cy + boundingY + window.scrollY}
                value={value}
                fill={fill}
              />
            )}
            isAnimationActive={false}
          />
          <Tooltip
            content={() => null}
            isAnimationActive={false}
            allowEscapeViewBox={{ x: true, y: true }}
          />
        </LineChart>
      </div>
    </TrendGraphContainer>
  )
}

TrendGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  trend: PropTypes.number.isRequired,
  dataKey: PropTypes.string.isRequired
}

export const TrendCell = ({
  row,
  value,
  testId,
  skipPercentage,
  showGraph,
  showZero
}) => {
  if (!value || !Number(value)) {
    return showZero ? 0 : '-'
  }

  const { historicalSearchTrends } = row?.original || {}

  return (
    <TrendContainer data-testid={testId}>
      {showGraph && historicalSearchTrends && (
        <TrendGraph
          data={historicalSearchTrends?.slice(-5)}
          trend={value}
          dataKey="estimatedExactSearchVolume"
        />
      )}
      <TrendIndicator
        isUp={parseInt(value, 10) >= 0}
        isVisible={Boolean(value)}>
        <Icon name="CARET_UP" width="30px" height="30px" />
      </TrendIndicator>
      <TrendValue>
        {Math.abs(Math.round(value))}
        {skipPercentage ? '' : '%'}
      </TrendValue>
    </TrendContainer>
  )
}
