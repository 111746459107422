import { TRANSITION_TIMING } from 'constants/sidebar'
import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { UnlockNavIcon } from 'icons/UnlockNavIcon'
import { LockNavIcon } from 'icons/LockNavIcon'
import { CollapseIconV2 as CollapseIcon } from 'icons/CollapseIcon/CollapseIconV2'

import { NAVBAR_STYLE } from 'constants/styles'
import { COLORS } from 'COLORS'

const Footer = styled.div`
  width: 100%;
  height: 52px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding-left: 16px;

  &:hover {
    cursor: pointer;
  }
`

const FooterWrapper = styled.div`
  margin-top: auto;
  width: 100%;

  hr {
    width: ${props =>
      props.collapsed ? '36px' : `calc(${NAVBAR_STYLE.width} - 16px)`};
    border-top: 1px solid ${COLORS.grey400};
    border-bottom: none;
    border-radius: 0.5px;
    margin-top: 0;
    margin-bottom: 0;
    transition: width 0.3s ${TRANSITION_TIMING};
  }

  &:hover {
    background-color: ${COLORS.grey950};
  }
`

const TextWrapper = styled.div`
  display: inline-block;
  margin-left: 14px;
  font-size: 14px;
  font-weight: 500;
  color: ${COLORS.grey400};
  overflow: hidden;
  white-space: nowrap;
  width: ${props => (props.collapsed ? '0%' : '100%')};
  opacity: ${props => (props.collapsed ? 0 : 1)};
  transition: transform 0.3s ${TRANSITION_TIMING},
    opacity 1s ${TRANSITION_TIMING}, width 0.3s ${TRANSITION_TIMING};
`

const SidebarToggle = ({ collapsed, locked, onClick }) => {
  const { t } = useTranslation(['sidebar'])

  const text = locked
    ? t('sidebar:menu.unlock', 'Unlock Menu')
    : t('sidebar:menu.lock', 'Lock Menu')

  const displayNavIcon = () => {
    return locked ? <UnlockNavIcon /> : <LockNavIcon collapsed={collapsed} />
  }

  return (
    <FooterWrapper collapsed={collapsed}>
      <hr />
      <Footer onClick={onClick} collapsed={collapsed}>
        <div style={{ position: 'relative', width: '18px' }}>
          <CollapseIcon
            height="18px"
            width="18px"
            opacity={1}
            title="Toggle Side Navigation"
            direction={collapsed ? 'right' : 'left'}
            transition={`all 0.3s ${TRANSITION_TIMING}`}
          />
          {displayNavIcon()}
        </div>
        <TextWrapper collapsed={collapsed}>{text}</TextWrapper>
      </Footer>
    </FooterWrapper>
  )
}

SidebarToggle.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  locked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}

export default withTheme(SidebarToggle)
